import Accordion from 'accordion-js';
import domReady from '@wordpress/dom-ready';

let isInitialized = false;

function initAccordions() {
  if (isInitialized) return;
  isInitialized = true;
  const currentAccordionID = window.location.hash;
  const containers = document.querySelectorAll('.accordion-container');
  containers.forEach((container) => {
    let currentAccordion = null;
    const openedAccordions = [];

    if (currentAccordionID) {
      currentAccordion = container.querySelector('.ac' + currentAccordionID);
    }

    if (currentAccordion) {
      const currentGroup = currentAccordion.parentNode;
      const currentIndex = Array.prototype.indexOf.call(
        currentGroup.children,
        currentAccordion
      );
      openedAccordions.push(currentIndex);
    }

    new Accordion(container, {
      duration: 400,
      showMultiple: true,
      openOnInit: openedAccordions,
    });
  });
}

domReady(() => {
  initAccordions();
});

const findstr = window.findstr || {};
if (findstr && findstr.hooks) {
  findstr.hooks.addAction(
    'afterSearchResults',
    'villemshafterSearchResults',
    function () {
      isInitialized = false;
      initAccordions();
    }
  );
}

/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/accordions');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/findstr-dropdowns');
require('./main/findstr-dropdown-date');
require('./main/findstr-results');
require('./main/breadcrumbs');
require('./main/footer-layout-mobile');
require('./main/sticky-navbar');
require('./main/alerts-bar');
require('./main/popup');

import domReady from '@wordpress/dom-ready';

const findstr = window.findstr || {};

function checkFilters() {
  const filtersContainer = document.querySelector(
    '.page-template-template-accordion-pdf-listing .findstrFieldContainer'
  );

  if (filtersContainer) {
    const filtersActive =
      !!filtersContainer.querySelector('input:checked') ||
      !!document.querySelector(
        '.findstrFieldContainer .ts-wrapper .ts-control .item '
      ) ||
      !!document.querySelector(
        '.findstr__filters__container input[type="search"]'
      ).value ||
      Array.from(filtersContainer.querySelectorAll('select')).some(
        (select) => select.value
      );

    const hasFiltersActiveContainer = document.querySelector(
      '.accordion-pdf-listing__findstr-results'
    );

    const defaultListingContainer = document.querySelector(
      '.listing--accordion-pdf-listing'
    );

    if (hasFiltersActiveContainer && defaultListingContainer) {
      if (filtersActive) {
        hasFiltersActiveContainer.style.display = 'block';
        defaultListingContainer.style.display = 'none';
      } else {
        hasFiltersActiveContainer.style.display = 'none';
        defaultListingContainer.style.display = 'block';
      }
    }
  }
  const resetFilters = document.getElementById('reset-filters');

  if (resetFilters) {
    resetFilters.addEventListener('click', function () {
      findstr.resetFilters();
    });
  }
}

domReady(() => {
  checkFilters();

  if (findstr && findstr.hooks) {
    findstr.hooks.addAction('findstrInit', 'checkFilters', function () {
      checkFilters();
    });
    findstr.hooks.addAction('afterSearchResults', 'checkFilters', function () {
      checkFilters();
    });
  }
});

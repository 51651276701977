import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.in_short_bloc');
  if (carousel) {
    const swiperSlide = document.querySelectorAll('.in_short_bloc__item');
    const fraction = document.querySelector(
      '.in_short_bloc-pagination-fraction'
    );
    const slideCount = swiperSlide.length;
    fraction.textContent = `1 / ${slideCount}`;

    if (1 < swiperSlide.length) {
      const InShortCarousel = new Swiper(carousel, {
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination-bullets, .swiper-pagination-fraction',
          type: 'bullets',
          clickable: true,
        },
        on: {
          slideChange: () => {
            fraction.textContent = `${
              InShortCarousel.snapIndex + 1
            } / ${slideCount}`;
          },
        },
        breakpoints: {
          200: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          992: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      });

      InShortCarousel.init();
    } else {
      document.querySelector(
        '.in_short_bloc-pagination-fraction'
      ).style.display = 'none';
      document.querySelector(
        '.in_short_bloc-pagination-bullets'
      ).style.display = 'none';
    }
  }
});

import domReady from '@wordpress/dom-ready';

const popupClosed = sessionStorage.getItem('popupClosed');

domReady(() => {
  const popup = document.getElementById('popup');

  if (popup && !popupClosed) {
    popup.style.display = 'flex';

    const closeBtn = popup.querySelector('.close-btn');
    if (closeBtn) {
      closeBtn.onclick = function () {
        popup.style.display = 'none';
        sessionStorage.setItem('popupClosed', 'true');
      };
    }
  }
});

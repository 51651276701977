document.addEventListener('findstrInit', function () {
  const findstr = window.findstr || {};
  findstr.hooks.addFilter(
    'setFacetsDistribution',
    'customSetFacetsDistribution',
    function (facets) {
      const monthOrder = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ];

      const monthFacetKey = 'post-year-month';

      if (facets.hasOwnProperty(monthFacetKey)) {
        const sortedMonths = {};
        const monthYearPairs = Object.keys(facets[monthFacetKey]).map((key) => {
          const [month, year] = key.split(' ');
          return { key, month, year: parseInt(year, 10) };
        });

        monthYearPairs.sort((a, b) => {
          if (a.year !== b.year) {
            return b.year - a.year;
          }
          return (
            monthOrder.indexOf(b.month.toLowerCase()) -
            monthOrder.indexOf(a.month.toLowerCase())
          );
        });

        monthYearPairs.forEach((pair) => {
          sortedMonths[pair.key] = facets[monthFacetKey][pair.key];
        });

        facets[monthFacetKey] = sortedMonths;
      }

      return facets;
    }
  );
});
